import { mutate, useSWRConfig } from "swr";

export const useRevalidateProducts = () => {
  const { cache } = useSWRConfig();
  return (productId) => {
    const getKey = (key) =>
      typeof key === "object" &&
      Array.isArray(key.tags) &&
      (key.tags.includes("#productRelated") ||
        key.tags.includes("#products") ||
        key.tags.includes(`#productId:${productId}`));
    void mutate(getKey, undefined, { revalidate: true });

    //revalidate infinity product
    for (const key of cache.keys()) {
      // eslint-disable-next-line quotes
      if (key.startsWith("$inf$") && key.includes('"#products"')) {
        void mutate(key, undefined, { revalidate: true });
      }
    }
  };
};

export const revalidateAgents = (agentId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#agentRelated") ||
      key.tags.includes("#agents") ||
      key.tags.includes(`#agentId:${agentId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateCompanyAgents = (companyAgentId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#companyAgentRelated") ||
      key.tags.includes("#companyAgents") ||
      key.tags.includes(`#companyAgentId:${companyAgentId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateBanners = (bannerId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#bannerRelated") ||
      key.tags.includes("#banners") ||
      key.tags.includes(`#bannerId:${bannerId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateDeposits = ({ orderId, depositId }) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#depositRelated") ||
      key.tags.includes("#deposits") ||
      key.tags.includes(`#depositsOfOrderId:${orderId}`) ||
      key.tags.includes(`#depositId:${depositId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateBlogs = (blogId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#blogRelated") ||
      key.tags.includes("#blogs") ||
      key.tags.includes(`#blogId:${blogId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateCategories = (categoryId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#categoryRelated") ||
      key.tags.includes("#categories") ||
      key.tags.includes(`#categoryId:${categoryId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateCoupons = (couponId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#couponRelated") ||
      key.tags.includes("#coupons") ||
      key.tags.includes(`#couponId:${couponId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateOrders = (orderId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#orderRelated") ||
      key.tags.includes("#orders") ||
      key.tags.includes(`#orderId:${orderId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateReviews = (reviewId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#reviewRelated") ||
      key.tags.includes("#reviews") ||
      key.tags.includes(`#reviewId:${reviewId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateRoles = (roleId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#roleRelated") ||
      key.tags.includes("#roles") ||
      key.tags.includes(`#roleId:${roleId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const useRevalidateTags = () => {
  const { cache } = useSWRConfig();
  return (tagId) => {
    const getKey = (key) =>
      typeof key === "object" &&
      Array.isArray(key.tags) &&
      (key.tags.includes("#tagRelated") ||
        key.tags.includes("#tags") ||
        key.tags.includes(`#tagId:${tagId}`));
    void mutate(getKey, undefined, { revalidate: true });
    for (const key of cache.keys()) {
      // eslint-disable-next-line quotes
      if (key.startsWith("$inf$") && key.includes('"#tags"')) {
        void mutate(key, undefined, { revalidate: true });
      }
    }
  };
};
export const revalidateTags = (tagId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#tagRelated") ||
      key.tags.includes("#tags") ||
      key.tags.includes(`#tagId:${tagId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUsers = (userId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#userRelated") ||
      key.tags.includes("#users") ||
      key.tags.includes(`#userId:${userId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateTopUps = (userId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#toopUpRelated") ||
      key.tags.includes("#topUps") ||
      key.tags.includes(`#topUpId:${userId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUserShippings = () => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes("#shippingByUserId");
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUserBillings = () => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes("#billingByUserId");
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUserNotes = (userId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes(`#userNotes:${userId}`);
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateLeadNotes = (leadId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes(`#leadNotes:${leadId}`);
  void mutate(getKey, undefined, { revalidate: true });
};
export const revalidateDealNotes = (dealId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes(`#dealNotes:${dealId}`);
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUserShippingById = (id) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes(`#shippingByUserId:${id}`);
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateUserBillingById = (id) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    key.tags.includes(`#billingByUserId:${id}`);
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateShippingRanges = (shippingRangeId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#shippingRangeRelated") ||
      key.tags.includes("#shippingRanges") ||
      key.tags.includes(`#shippingRangeId:${shippingRangeId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateQuotes = (quoteId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#quoteRelated") ||
      key.tags.includes("#quotes") ||
      key.tags.includes(`#quoteId:${quoteId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const revalidateLeads = (leadId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#leadRelated") ||
      key.tags.includes("#leads") ||
      key.tags.includes(`#leadId:${leadId}`));
  void mutate(getKey, undefined, { revalidate: true });
};
export const revalidateDeals = (dealId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#dealRelated") ||
      key.tags.includes("#deals") ||
      key.tags.includes(`#dealId:${dealId}`));
  void mutate(getKey, undefined, { revalidate: true });
};
export const revalidateCampaigns = (campaignId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#campaignRelated") ||
      key.tags.includes("#campaigns") ||
      key.tags.includes(`#campaignId:${campaignId}`));
  void mutate(getKey, undefined, { revalidate: true });
};

export const useRevalidateExpenses = () => {
  const { cache } = useSWRConfig();
  return (id) => {
    const getKey = (key) =>
      typeof key === "object" &&
      Array.isArray(key.tags) &&
      (key.tags.includes("#expenseRelated") ||
        key.tags.includes("#expenses") ||
        key.tags.includes(`#expenseId:${id}`));
    void mutate(getKey, undefined, { revalidate: true });
    for (const key of cache.keys()) {
      // eslint-disable-next-line quotes
      if (key.startsWith("$inf$") && key.includes('"#expenses"')) {
        void mutate(key, undefined, { revalidate: true });
      }
    }
  };
};

export const useRevalidateExpenseTypes = () => {
  const { cache } = useSWRConfig();
  return (id) => {
    const getKey = (key) =>
      typeof key === "object" &&
      Array.isArray(key.tags) &&
      (key.tags.includes("#expenseTypeRelated") ||
        key.tags.includes("#expenseTypes") ||
        key.tags.includes(`#expenseTypeId:${id}`));
    void mutate(getKey, undefined, { revalidate: true });
    for (const key of cache.keys()) {
      // eslint-disable-next-line quotes
      if (key.startsWith("$inf$") && key.includes('"#expenseTypes"')) {
        void mutate(key, undefined, { revalidate: true });
      }
    }
  };
};

export const revalidateDeliveries = (deliveryId) => {
  const getKey = (key) =>
    typeof key === "object" &&
    Array.isArray(key.tags) &&
    (key.tags.includes("#deliveryRelated") ||
      key.tags.includes("#deliveries") ||
      key.tags.includes(`#deliveries:${deliveryId}`) ||
      key.tags.some((k) => k.startsWith("#deliveryId")));
  void mutate(getKey, undefined, { revalidate: true });
};
