import { useState, useEffect } from "react";

const useClickDebounce = (callback, delay) => {
  const [isReady, setIsReady] = useState(true);

  const debounce = (...args) => {
    if (!isReady) return;

    setIsReady(false);
    callback(...args);
  };

  useEffect(() => {
    if (!isReady) {
      const timer = setTimeout(() => setIsReady(true), delay);
      return () => clearTimeout(timer);
    }
  }, [isReady, delay]);

  return debounce;
};

export default useClickDebounce;
